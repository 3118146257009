export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["俄语"])},
  "views": {
    "Login": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录名："])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码："])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["忘记密码？"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首次访问？"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码恢复"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话或电子邮件："])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码恢复"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓："])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名："])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父名："])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出生日期："])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找到用户"])}
    },
    "History": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间从"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间到"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类型"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数/评论"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到操作"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作金额"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入："])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 笔，总金额"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出："])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作类型"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付、服务付款"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部转账"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单支付"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的账户转账"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建加密货币支票"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活加密货币支票"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入/支出"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["= 等于"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≠ 不等于"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["> 大于"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≥ 大于或等于"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["< 小于"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["≤ 小于或等于"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功完成"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理中"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作历史"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户充值"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择账户"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在交易所交换"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值地址"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的钱包内转账"])}
    },
    "Home": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字护照"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户 ID："])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册日期："])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后授权："])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资产"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有账户"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于确认的密码"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要确认交易，您需要创建一个确认密码。"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["热门支付"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新闻"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["折叠"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多..."])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有资产"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])}
    },
    "InfoReg": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册信息"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已确认"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公开"])}
    },
    "Pay": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查找"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在组中"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付组"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账账户"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])}
    },
    "Payin": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值账户"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付方式"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户充值"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账详情"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查转账"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账信息"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["说明"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值地址"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值地址"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回任务列表"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账地址"])}
    },
    "Return": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复操作"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作历史"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户列表"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知错误"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["令牌列表"])}
    },
    "Transfer": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账账户："])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额："])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论："])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易保护"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码："])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护期限："])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["天"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另存为模板"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账给其他用户"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护代码是收款人必须输入的代码，以完成交易。受保护的转账收款人会立即在“操作历史”中看到，但只有在交易卡中输入保护代码后才能使用资金。如果支付期限到期，资金将返还给发送人。"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账地址"])}
    },
    "TransferSelf": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从哪里"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到哪里"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的账户转账"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钱包内转账"])}
    },
    "Unexpected": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出了点问题"])}
    },
    "Keyts": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加信息"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新地址"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新账户"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除选定"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资产"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户列表"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户名称"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除账户吗？"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除账户"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到资产"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到账户"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名地址"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除地址吗？"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除地址"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址类型"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户类型"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产："])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建地址"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新地址"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新账户"])}
      }
    },
    "Safety": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全"])},
      "Antiphishing": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反钓鱼设置是使用账户时的额外保护机制。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您设置的欢迎短语将显示在账户的每一页顶部。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正确的短语将表明您在正确的网站上，不正确的短语将是一个信号：不要进行操作，立即联系我们的系统安全部门。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用反钓鱼："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反钓鱼短语："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["反钓鱼"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改将在下次授权后生效"])}
      },
      "IP": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址访问设置将阻止您（或攻击者）从未列入允许列表的地址访问系统。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据IP地址限制访问账户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加地址"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址： "])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址块： "])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至："])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的IP： "])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址列表"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从地址"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至地址"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["访问"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有地址"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入IP地址"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP地址无效"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未指定任何IP地址或地址块。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有IP地址被允许授权。您将无法访问您的账户。"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据IP访问"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从："])}
      },
      "Magic": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词是额外的安全设置。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果启用了魔法词，每次授权时，系统将要求您输入魔法词中的一些字符（按其序号）。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词："])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要求魔法词："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录账户时："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过商家支付时："])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用卡时："])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["魔法词"])}
      },
      "Onekey": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密钥大大提高了系统的安全性。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录时，程序不仅要求您输入登录名和密码，还要求输入下一个一次性密钥。激活依次进行。最后一个密钥有效，直到您创建新的一组密钥。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用一次性密钥"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥列表"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一次性密钥"])}
      },
      "Password": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码："])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"新密码\"和\"确认密码\"不匹配。"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改密码"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度为4至63个字符。允许使用拉丁字母、数字和符号"])}
      },
      "PayPassword": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["旧密码："])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新密码："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认密码："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["\"新密码\"和\"确认密码\"不匹配。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不应包含西里尔字母"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建确认密码"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改确认密码"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回主页"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["长度为4至65个字符。允许使用拉丁字母、数字和符号"])}
      },
      "SecretQuestion": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您忘记了账户访问密码，可以通过回答秘密问题来恢复。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此部分，您可以更改秘密问题/答案。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘密问题"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的问题："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["秘密问题"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自定义问题"])}
      }
    },
    "Stock": {
      "CurrTransfer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["余额 "])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易对："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换类型："])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场交换 - 将根据申请执行时的最佳汇率进行交换。注意，收到的金额可能会因交换时的申请而有所变化，并且可能与计算的金额不同。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定价格交换根据您指定的金额和汇率进行。将提交申请。如果指定的汇率与市场汇率相差很大，则申请完成可能需要很长时间。"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["预测汇率："])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未定义汇率，"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["建议自行设置汇率"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额："])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用所有"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费："])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费："])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计："])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按市场"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（按最佳对手报价快速交换）"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定汇率 "])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（提交指定汇率的申请）"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有合适的账户"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时变化"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时波动"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24小时交易量"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额不能为零！"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交换"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要查看汇总数据，请选择交易对。"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不能交换相同的资产"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])}
      },
      "StockMarket": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易所"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场。摘要"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类似报价"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手报价"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的申请"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最近交易"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期和时间"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有报价"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有交易"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有申请"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全完成"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未开始处理"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始处理"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全取消"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成并取消"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有申请"])}
      },
      "OrderList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有申请"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有申请"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小给出金额"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大给出金额"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小收到金额"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大收到金额"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易尝试次数"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出货币"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到货币"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣款账户"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用账户"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次级交易员列表"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择账户"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间从"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间到"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择日期类型"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结束"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定部分和可变部分"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定给出金额"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定收到金额"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申请类型"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户希望快速交换，按最佳对手报价"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["按固定汇率交换"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未开始处理"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始处理"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全完成"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完全取消"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["部分完成并取消"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转出的资产"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收的资产"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期类型"])}
      },
      "Offers": {
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出金额"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到金额"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有报价"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["P2P报价"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方ID"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价代码"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方已验证"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅限已验证用户"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法与自己交易"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])}
      }
    },
    "VA": {
      "ListVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编号"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标志"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使命"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产列表"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无加密资产。"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行代币"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["市场"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["初级"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["次级"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与其他资产匹配"])}
      },
      "NewVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称："])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称是加密资产的全称，每个人都会看到：发行者、买家、卖家、处理器等。字符串长度不超过 40 个字符。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TICKER 是加密资产的缩写，长度为 3 至 10 个字符。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择您的加密资产最适合的类别"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持的格式：png, jpg, jpeg。文件大小不超过1MB，500*500像素"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述发行方发行该加密资产的原因，在哪个领域工作或将在哪个领域工作，其使命是什么"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标志："])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目标/使命/目的："])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度："])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行量："])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位成本："])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额的小数位数。例如，大多数法定货币（如美元或欧元）的精度为 2。"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行加密资产的单位数。加密资产创建交易完成后，您的账户将立即获得发行量。"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位声明价值："])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持："])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户："])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["承担义务的人："])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虚拟数字资产发行决议："])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["将操作导出到区块链："])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链类型："])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用VA使用："])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制/制裁："])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定上述列表中哪些国家的公民或税务居民被禁止在其营业额中使用加密资产："])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他限制和条件："])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["托管地址："])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["白皮书地址："])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件："])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telegram："])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter："])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook："])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他联系方式："])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回上一页"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建加密资产"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称和目的"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["财务特征"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["导出到区块链"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制和限制"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未定义"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与法定货币和其他资产的固定挂钩"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人（发行加密资产的人）"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行人（发行加密资产的人）"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发件人"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我（启用赞助）"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产投放"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产是指可使用区块链技术以电子方式转让和存储的价值或权利的数字代表。加密资产不包括受政府单独监管的法定货币、证券和其他金融工具和资产。"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密资产"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行政"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验收"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝受理理由"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价人账户"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价人的绑定货币账户"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要约人是一级市场购买/销售交易中资产发行人的代表。\n该帐户是被动的。\n可以是资产发行账户"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该账户是被动的，以货币挂钩。\n必须与之前的帐户属于同一用户"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许用户在 ATM 上购买资产"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后购买表"])},
        "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二级市场交易通过网关进行"])},
        "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["售后销售表格"])},
        "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["二级市场交易通过网关进行"])},
        "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许在交易所进行资产交易"])},
        "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许通过P2P进行资产交易"])},
        "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您是个体工商户或法人代表，您需要下载PDF格式的数字资产发行决策文件。\n该解决方案必须包含虚拟资产的所有主要特征，并使用个体企业家或该法人实体的电子签名进行签名。\n发行数字资产的决定示例可以在我们网站页面的“文件”部分找到"])},
        "70": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["放置虚拟资产"])},
        "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["替代发行账户"])},
        "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从发行人账户中选择"])}
      },
      "CodeVa": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["虚拟资产"])}
      }
    },
    "Notify": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知设置"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知操作"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权（登录账户、移动应用等）"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在事件“使用卡进行金融操作”时通知"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支出（任何付款、转账、服务支付等）"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资金收入"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过内部邮件收到信件"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到支付账单"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改安全设置"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认操作"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认授权"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在“使用卡进行金融操作”时确认"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认更改安全设置"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过消息中的代码确认支出"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未保存更改，是否保存？"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不保存"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
    },
    "Masspayment": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入日期"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["处理日期"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款数量"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中成功"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中错误"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗支付"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. 参数"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到付款"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以同时进行多个付款。为此，您需要首先创建一个描述付款的文件，格式为\"XLS\", \"XLSX\", \"CSV\"或\"XML\"。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件分析"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["参数"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件信息"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总付款数："])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总金额："])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款文件"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣款账户"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["执行付款"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载付款文件"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗支付和转账"])}
      },
      "Info": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["大宗支付服务允许快速将资金转移到其他用户并执行向服务提供商的支付操作。"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在部分"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载付款文件"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以加载Excel格式（XLS、XLSX、CSV）或XML格式的文件。"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["任务列表"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许查看先前创建的大宗支付请求的状态。"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在Excel表格编辑器中创建文件。"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下载示例文件"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重要说明："])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件格式 - CSV、XLS或XLSX。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件必须没有标题。"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为了防止长数字变成指数形式（例如，“5,46546E+15”）并且不截断前导零，可以在数字前面放置一个单引号(')，或者在输入数据之前将单元格格式设置为文本格式。"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务提供商支付的列格式"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件中的列列表："])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作码"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。从提供的操作员列表中选择"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["这里"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。对于所有移动通信运营商，可以指定操作码为7000 - 在这种情况下，将根据电话号码自动确定操作员。"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额在进行转账的资产中注明。如果对方没有这种资产，系统的转换率将自动应用于交易。"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付评论"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写不是必须的，可以留空；长度不超过255个字符。"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数1"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。输入操作员要求的参数值，例如电话号码。许多操作员只需要一个支付参数（例如移动通信、互联网、商业电视等），但如果参数数量超过一个，您需要在第一列的相邻列中输入所有参数。"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数2"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数N"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以在支付任何操作员时查看支付参数："])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户大宗转账的列格式"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作码"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。指定为0或完全不指定。"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额显示在进行转账的账户资产中。如果对方没有这种资产，系统的转换率将自动应用于交易。"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付评论"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写不是必须的，可以留空；长度不超过255个字符。"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款账户"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。在此输入需要转账到的用户账户号码（20位数字）。"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用代理人批量贷款发放的列格式"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。该行表明付款是贷款。"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。金额以信贷产品的货币表示。"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["贷款评论"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写不是必须的，可以留空；长度不超过255个字符。"])},
        "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["借款人ID"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。请注意，借款人必须持有Mfo-BorrowPerson数字护照。"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信贷产品代码"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。您可以在手动提供贷款的信贷产品列表中找到它。"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。只是期间的数量（具体使用的期间由信贷产品定义，例如，这可以是天、周、月等。）"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作码（表单号码）"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。填写此字段和以下字段不是必须的，如果不填写，借款人以后可以通过自己的账户独立提取贷款。"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果有错误则取消"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。如果转账交易错误，取消（1）发放的贷款，或者保留以供后续独立提取（0）。"])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数1"])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["。输入操作员要求的参数值，例如电话号码。许多操作员只需要一个支付参数（例如移动通信、互联网、商业电视等），但如果参数数量超过一个，您需要在第一列的相邻列中输入所有参数。"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数2"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付参数N"])}
      }
    },
    "Support": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最后消息"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有请求"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["类别"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["优先级"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主题"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息内容"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以上传一个或多个文件。文件最大大小为10MB。"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["低"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["正常"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["高"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术支持"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动请求"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存档"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新请求"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由操作员处理"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已创建"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作正在进行中，请等待操作员的答复"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已关闭"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求已关闭"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有新消息"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自操作员的新消息"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有新消息"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送到存档"])}
    },
    "Templates": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["充值"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提现"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作员"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字段"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有模板"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除模板编号"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名模板"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重命名"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])}
    },
    "CorrInfo": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对手信息"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / 账户 / 电子邮件 / 电话"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID / 加密地址 / 电子邮件 / 电话"])}
    },
    "Merchant": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["常规信息"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付信息"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控制签名"])},
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的商店"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到商店"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建小部件代码"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建支付链接"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除商店"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您确定要删除商店"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册商店"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付模式"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接受"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册商店"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在系统中注册的电子商店允许通过系统的商家接受客户支付。"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子商店、兑换点和任何其他希望接受支付的互联网资源都注册为商店。"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称或品牌"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店的URL地址"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店的简短描述"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务类型"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店活动"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密钥"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许自动支付"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送结果到脚本"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["脚本地址"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功支付后返回"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败支付后返回"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不少于30个字符。允许字符：拉丁字母、数字"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用于检查支付系统发送和接收的数据的完整性和真实性"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回商店列表"])}
      },
      "Invoices": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发票列表"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到发票"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店"])}
      }
    },
    "P2P": {
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国内资产"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称 RU"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同义词"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果填写，广告将仅对指定国家的用户可见"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与资产挂钩"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["单位"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简写"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多信息"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["细节"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转账方向"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最高"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待时间（分钟）"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告已激活"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅通过链接可用"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅对已验证用户可用"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回上一页"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建广告"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自己的外部资产"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不要绑定"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降低汇率"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
        "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" 没有这种资产的账户"])},
        "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
        "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定汇率"])},
        "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率正确"])},
        "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["降低汇率"])},
        "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["恢复"])},
        "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最低大于最高"])},
        "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你想在哪里和如何接收转账。例如：通过银行应用程序将其转移到电话号码+499990001111（收款人：亚历山大·N）"])},
        "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给出"])},
        "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
        "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求的详细信息"])},
        "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["К承兑人应提供哪些详细信息，以便您可以向他转账"])},
        "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])},
        "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择资产"])},
        "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您想获得什么：内部资产中的资金，例如比特币，还是外部资产中的资金，例如托肯戈德？"])},
        "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请写下这笔交易的特点，例如：\\\"外部资产仅在面对面时转移\\\""])},
        "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产所属的价值类型，例如 \\\"现金\\\""])},
        "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在平台上显示的资产名称"])},
        "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产也可以用列表中的词语来命名"])},
        "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与现有资产的匹配"])},
        "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：升，件，小时，箱"])},
        "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["例如：升，件，小时，箱"])},
        "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产值中小数点后的位数"])},
        "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您选择创建一个尚不存在的资产，请填写其特征"])},
        "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给各方履行条件的时间"])},
        "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产是指存在于系统\\\"纽瑞尔提\\\"中的资产。外部资产是指不存在于系统\\\"纽瑞尔提\\\"中的资产。"])},
        "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未绑定"])}
      },
      "SingleSwap": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["原因"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消交易"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认参与交易"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝交易"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["延长等待时间"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消交易"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户在交易中的状态未定义。出了点问题"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求仲裁"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同意仲裁"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知完成转账"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收到转账"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评估交易"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送评估"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["明天"])}
      },
      "Offers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您给"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限制"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有交易"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["好处"])}
      },
      "ActiveSwaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易代码"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有活动交易"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的交易"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活动"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])}
      },
      "Swaps": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产描述"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有广告"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的广告"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的广告"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新广告"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活动"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["方向"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["给出"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["限额"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发布日期"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户余额"])}
      },
      "RequestP2POffer": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易请求"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["报价"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卖方"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["超时"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["各方等待时间"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["汇率"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您给"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您收到"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求交易"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建账户"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未选择账户"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额不属于以下范围："])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["分钟"])}
      },
      "CheckCurrP2POffers": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["转出"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接收"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["资产"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我给"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我收到"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部资产"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["找不到资产"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择："])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["内部"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部"])}
      },
      "P2PChat": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])}
      },
      "RequestP2PArbitrage": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["请求仲裁"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
      },
      "ExternalCurr": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["外部资产"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到资产"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计量单位"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建日期"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间从"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["期间到"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有资产"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["昨天"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["周"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["月"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到资产"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["作者"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])}
      }
    },
    "p2p": {
      "Edit": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["描述"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["细节"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最小"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最大"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额限制"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等待时间（分钟）"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["广告活动"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅通过链接可用"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["仅对已验证用户可用"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])}
      },
      "ExternalCurrencies": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑外部资产"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["组"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["同义词"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["绑定到资产"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计量单位"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["缩写"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["精度"])}
      }
    },
    "offers": {
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开始"])}
    },
    "MultiSelect": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全选"])}
    },
    "ATM": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密货币ATM"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点击以全屏显示表格"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经销商"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活跃"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建加密货币ATM"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密货币ATM"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要信息"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["开放时间"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录名"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装位置描述"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["标准化"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非标准化"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作模式"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期一"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期二"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期三"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期四"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期五"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期六"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["星期日"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["午休时间"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复密码"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用现有帐户"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建新帐户"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["工作时间（参考信息）"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
      "39": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
      "40": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到加密机"])},
      "41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动柜员机号码"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纬度"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经度"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帮助热线"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["子经销商 ID"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
      "49": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["货币"])},
      "50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中转账户"])},
      "51": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建密码机"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑加密货币"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是的"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["禁止"])},
      "57": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
      "58": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏家名单"])},
      "59": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收集器"])},
      "60": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无法转到托收余额 - 无数据"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏家名单"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收集器"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验收"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])}
    },
    "Echeck": {
      "List": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子支票"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建支票"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活动"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已创建"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冲销账户"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系列"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一条评论"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["积极地"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不活跃"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有支票"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])}
      },
      "New": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建电子收据"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["冲销账户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每张支票的面额"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一条评论"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支票数量"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后退"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创造"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系列："])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字："])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代码："])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["创建支票"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出口"])}
      },
      "Activate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活电子支票"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存款账户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["系列"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["激活码"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["启用"])}
      }
    },
    "Error": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["糟糕，出现错误！"])}
    },
    "ATMIncass": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM机集合"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收藏"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["过滤器"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重置"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不显示档案馆藏"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不显示已取消的集合"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点数"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地位"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有收藏"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已提交收集申请"])}
    },
    "ATMIncassItem": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加收藏"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基础知识"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动售货机"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点数"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每个申请的卡数"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票据承兑人"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现金收集者"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏家须知"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收藏评论"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加载无序"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更换纸币接收盒"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡带评论"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对卡片的评论"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从拒绝发卡器盒中取出所有卡片"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从管中取出所有卡片"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["点号为必填字段，必须填写"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 1"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 2"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 3"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒 ID 4"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评级 1"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["钞箱 1 中的钞票数量"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["试剂盒 1 中的数量"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带等级 2"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["纸盒 2 中的钞票数量"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评论1"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒ID"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评级"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单张数"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡带评论"])}
    },
    "ATMIncassBalance": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盒式磁带数量"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包埋盒ID"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["盒式磁带收藏编号"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带评级"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已上传"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动提款机"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["实际上"])},
      "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数量"])},
      "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卡带评论"])},
      "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无数据可显示"])},
      "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["设备集合"])},
      "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有效的"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["c"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["经过"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["遗体被运走"])},
      "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服务器"])},
      "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单张数"])},
      "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动提款机"])},
      "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账单张数"])},
      "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["和"])},
      "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自动售货机"])},
      "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝/撤回"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["票据承兑人"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拒绝卡片"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["牌"])},
      "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["当前的"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["卸载的"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM 收款余额"])}
    },
    "ATMIncassStatuses": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择状态"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["领取申请已提交"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款申请已取消"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["批准执行的集合"])},
      "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由收银员进行收款（钱被装入钱箱）"])},
      "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁带移交给收藏家（钱在路上）"])},
      "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安装在ATM机上的现金收集"])},
      "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["由于失败，另一个集合超载"])},
      "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["该集合已被 ATM 上的另一个集合取代（仍在途中）"])},
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收款余额已过帐（收款将发送至档案）"])}
    },
    "CollectorsList": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行动"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑有关收集器的信息"])}
    },
    "InfoCollector": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["数字"])},
      "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
      "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
      "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["节省"])}
    },
    "ChoiseKeyt": {
      "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择要约人的帐户"])}
    }
  },
  "components": {
    "service": {
      "Registration": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["个人账户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商业账户"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名："])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["父名：（如有："])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用拉丁字母以及连字符和撇号"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bond"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用拉丁字母以及连字符、空格和撇号”。"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["James"])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可使用拉丁字母，以及连字符、倒逗号、空格、数字和撇号"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Company Ltd."])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电话"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子邮件"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["拉丁字母（大写和小写）、数字、符号 _ - . ! $ % ^ * ="])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复密码"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码不匹配"])},
        "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
        "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册"])},
        "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一步"])},
        "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["用户数据"])},
        "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码"])},
        "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入正确的电子邮件地址或电话号码。电话号码应以国际格式指定，例如：+499001234567"])},
        "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您已成功注册"])},
        "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["现在您可以"])},
        "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登录到您的账户"])},
        "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您的账户将由我们的员工检查，激活结果我们稍后会通知您"])},
        "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已发送确认注册链接的电子邮件"])},
        "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到您的电子邮件地址"])},
        "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一次登录后，您将被要求确认电话号码"])}
      },
      "Members": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收件人："])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到用户"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["联系方式"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择用户"])}
      },
      "modal": {
        "Answer": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])}
        },
        "Confirm": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])}
        },
        "History": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总体参数"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户余额变化"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作结束时："])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作开始时："])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加参数"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链操作"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回调"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一次尝试："])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["时间"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["响应"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易保护"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护期限："])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["到"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保护代码："])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板参数"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板名称："])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消操作"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复操作"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存模板"])},
          "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
          "24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易编号"])},
          "25": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作"])},
          "26": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["对应方"])},
          "27": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
          "28": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
          "29": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
          "30": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
          "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果"])},
          "32": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付表单编号"])},
          "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收入/支出"])},
          "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址余额变化"])},
          "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认收入"])},
          "36": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还资金给发送人"])},
          "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可疑操作"])},
          "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作选项："])}
        },
        "Rename": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称："])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])}
        },
        "MembersModal": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到用户"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["关闭"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
        },
        "PayPass": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["密码："])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入密码确认"])}
        },
        "PayStatus": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重复操作"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作历史"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户列表"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知错误"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["模板"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的资产"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有关新资产的信息"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加问题"])}
        },
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["输入："])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])}
        },
        "Address": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择地址"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住地"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["国家"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地区"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区域"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市区域"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["居住地"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["街道"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址附加项"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["详细信息"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["房子"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["邮政编码"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址"])}
        },
        "SupportList": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["技术支持请求"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["来自"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聊天"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["添加消息"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["您可以上传一个或多个文件。文件最大大小为10MB。"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["消息内容"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发送"])}
        },
        "Template": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名称"])}
        },
        "WidgetData": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["为...创建小部件"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["账户"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付金额"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功时的操作"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["失败时的操作"])}
        },
        "PayHref": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["生成支付代码/链接"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商店操作编号"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加参数"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["成功URL"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["错误URL"])},
          "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通知URL"])},
          "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户成功支付后返回的URL"])},
          "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["客户支付失败后返回的URL"])},
          "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付结果通知的URL"])},
          "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["固定金额"])},
          "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["复制"])},
          "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["检查"])},
          "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付链接"])},
          "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
          "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文档"])},
          "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付帐单的有效期（分钟），默认60分钟"])},
          "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接有效期"])},
          "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["链接"])},
          "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
          "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小部件"])}
        },
        "ReEshop": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["编辑商店"])}
        },
        "Questionnaire": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您看到此消息，表单未收到（但您不应看到此消息）"])}
        },
        "Emission": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["额外发行"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密资产"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["覆盖账户"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费账户"])},
          "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["发行量"])},
          "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["评论"])},
          "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["继续"])}
        }
      },
      "Comiss": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["扣除"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信息"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入账"])}
      },
      "File": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择文件"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["允许格式："])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未知格式"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件太大，最大大小："])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择或拖放文件到此处上传"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上传"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件已上传"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["文件"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["删除"])}
      },
      "PayStatusTemplate": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额："])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["结果："])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易："])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刷新状态"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有用链接"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链："])}
      },
      "SafetyRes": {
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其他安全设置"])}
      },
      "modlas": {
        "Repeat": {
          "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要确认"])},
          "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权需要一次性密钥确认"])},
          "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授权需要魔法词确认"])},
          "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["注册需要短信码确认"])}
        }
      },
      "GetInputs": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["从"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["至"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["字符"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["无效值"])}
      },
      "TableList": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜索"])}
      },
      "topmenu": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["加密ID: "])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["语言"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["早上好"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下午好"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晚上好"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["晚安"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退出"])}
      },
      "pagination": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示："])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["总计"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每页"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["显示"])}
      }
    },
    "Payin": {
      "Table": {
        "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["确认支付"])},
        "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["计算退还费用"])},
        "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还支付"])},
        "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["区块链哈希"])},
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AML风险"])},
        "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手续费"])},
        "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入账"])},
        "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["状态"])},
        "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果您同意更改的条件，请确认入账："])},
        "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果这些条件不适合您，您可以将资金退还给发送方，并扣除退还费用："])},
        "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易"])},
        "14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金额"])},
        "15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["有AML风险"])},
        "16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此重新计算了手续费"])},
        "17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["因此入账金额为"])},
        "18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["没有检测到新收入"])},
        "19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支付日期"])},
        "20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["退还"])}
      }
    }
  },
  "iews": {
    "Echeck": {
      "New": {
        "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后退"])}
      }
    }
  }
}